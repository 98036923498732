




























































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

// types
import { CourseType, EducationCourseShortResource } from '@/store/types'
import MasterEducationModule from '@/store/modules/master/education'
import { countDayInMonth, formatDate } from '@/utils/functions'
// utils
import { subjectsListOnID } from '@/utils/constants'

@Component

export default class CourseMyCard extends Vue {
  @Ref()
  private chipsWrapper!: HTMLDivElement

  @Prop({ required: true })
  private course!: EducationCourseShortResource

  @Prop({ required: true })
  private currentTimeRaw!: string

  private get teachers() {
    return this.course.teachers
  }

  private get isSpecial () {
    return this.course.type.value === CourseType.SPECIAL
  }

  private get countDay() {
    return countDayInMonth(this.course.extendedTo)
  }

  private get month() {
    return formatDate(this.course.extendedTo, 'MMM yyyy')
  }

  private stateOfCard(hover: boolean) {
    let classes = []
    if (hover)
      classes.push('elevation-24')
    else
      classes.push('elevation-0')

    return classes
  }

  private handleRedirect() {
    MasterEducationModule.setCurrentMasterGroupID(this.course.masterGroup.value as number)
    this.$router.push(({ name: 'master.courses.lessons' }))
      .catch(() => {return})
  }

  private handleProlongation () {
    this.$emit('prolongation', this.course)
  }

  private getSubject(subjectId: number) {
    return subjectsListOnID[subjectId]
  }

  private mounted() {
    this.handleChipsScroll()
  }

  private beforeDestroy() {
    this.destroyChipsScrollHandler()
  }

  // Скролл чипсин в карточке
  private speed = 1.5
  private leftOffset = 0
  private scrollStartX = 0
  private drag = false

  private startDrag(event: MouseEvent) {
    this.drag = true
    this.scrollStartX = event.pageX - this.chipsWrapper.offsetLeft
  }

  private endDrag() {
    this.drag = false
    this.leftOffset = this.chipsWrapper.scrollLeft
  }

  private updateScroll(event: MouseEvent) {
    if(this.drag) {
      this.chipsWrapper.scrollLeft = this.leftOffset + (this.scrollStartX - event.pageX) * this.speed
    }
  }

  private handleClick(event: MouseEvent) {
    const isChipsArea = !!(event.target as HTMLElement)?.closest('.course-card__chips')
    if(isChipsArea) {
      event.preventDefault()
      event.stopImmediatePropagation()
    }
  }

  private handleChipsScroll() {
    this.$bus.$on('chipsMouseup', this.endDrag)
    this.chipsWrapper.addEventListener('mousedown', this.startDrag)
    this.chipsWrapper.addEventListener('mousemove', this.updateScroll)
    this.chipsWrapper.addEventListener('click', this.handleClick)
  }

  private destroyChipsScrollHandler() {
    this.$bus.$off('chipsMouseup', this.endDrag as any)
    this.chipsWrapper.removeEventListener('mousedown', this.startDrag)
    this.chipsWrapper.removeEventListener('mousemove', this.updateScroll)
    this.chipsWrapper.removeEventListener('click', this.handleClick)
  }
}
