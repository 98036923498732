









































import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'

// components
import CourseMyCard from '@/components/cards/CourseMyCard.vue'
import PaginationWrapper from '@/components/_uikit/PaginationWrapper.vue'
import StoreBannerSlider from '@/components/StoreBannerSlider.vue'
import Select from '@/components/_uikit/controls/Select.vue'
// mixins
import CurrentTimeMixin from '@/mixins/CurrentTimeMixin'
// store
import MasterCoursesModule from '@/store/modules/master/courses'
import MasterEducationModule from '@/store/modules/master/education'
import DictionaryModule from '@/store/modules/dictionary'
import { EducationCourseShortResource, SaleBannerResource } from '@/store/types'

@Component({
  components: {
    CourseMyCard,
    PaginationWrapper,
    StoreBannerSlider,
    Select,
  },
})
export default class CoursesMy extends Mixins(CurrentTimeMixin) {
  private discounts: SaleBannerResource[] = []
  private showBanners = true

  private filter = {
    type: '',
    subjectId: '',
  }

  private get courses () {
    return MasterCoursesModule.myCourses
  }

  private get filteredCourses () {
    let result = this.courses

    if (this.filter.subjectId) {
      result = result.filter(course => course.subject.value === this.filter.subjectId)
    }

    if (this.filter.type) {
      result = result.filter(course => course.type.value === this.filter.type)
    }

    return result
  }

  private get courseTypes () {
    return DictionaryModule.courseTypes
  }

  private get subjects () {
    return DictionaryModule.subjects
  }

  private mounted () {
    this.$bus.$on('updateMyCourses', this.fetchCourses)
    this.fetchDiscounts()

    // Для скролла чипсин в карточке
    document.addEventListener('mouseup', this.handleMouseup)
  }

  private destroyed() {
    this.$bus.$off('updateMyCourses', this.fetchCourses as any)
    document.removeEventListener('mouseup', this.handleMouseup)
  }

  private fetchCourses () {
    MasterCoursesModule.fetchMyCourses()
      .then(() => {
        // Если количество курсов больше, чем количество мастер-групп пользователя, то нужно обновить список мастер-групп
        if (this.courses.length > MasterEducationModule.masterGroups.length) {
          MasterEducationModule.fetchMasterGroups()
            .catch(this.notifyError)
        }
      })
      .catch(this.notifyError)
  }

  private handleSelectCourse (course: EducationCourseShortResource) {
    this.$bus.$emit('show-prolongation-modal', course.id)
  }

  private handleCloseBanners() {
    this.showBanners = false
    MasterCoursesModule.closeBanner()
  }

  @Bind
  @Debounce(500)
  private fetchDiscounts() {
    MasterCoursesModule.fetchUsersDiscounts()
      .then(response => {
        this.discounts = response
      })
      .catch(this.notifyError)
      .finally(() => {
        this.fetchCourses()
      })
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Мои курсы',
    }
  }

  private handleMouseup() {
    this.$bus.$emit('chipsMouseup')
  }
}
