import { Component } from 'vue-property-decorator'

import NotifyMixin from '@/mixins/NotifyMixin'
import DictionaryModule from '@/store/modules/dictionary'

@Component
export default class CurrentTimeMixin extends NotifyMixin {
  protected get currentTime() {
    return DictionaryModule.currentTime
  }

  protected get currentTimeRaw() {
    return DictionaryModule.currentTimeRaw
  }

  protected created() {
    DictionaryModule.fetchConstants()
      .catch(this.notifyError)
  }
}
