

















import { Component, Vue, Prop } from 'vue-property-decorator'

// components
import Tag from '@/components/_uikit/Tag.vue'
// store
import { SaleBannerResource } from '@/store/types'

@Component({
  components: {
    Tag,
  },
})
export default class MasterCoursesBannerCard extends Vue {
  @Prop({ required: true })
  private sale!: SaleBannerResource

  @Prop({ default: '' })
  private color!: string


}
