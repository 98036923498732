














import { Component, Prop, Vue } from 'vue-property-decorator'

import { ITableOptions, PaginatorMeta } from '@/store/types'

@Component
export default class PaginationWrapper extends Vue {
  @Prop({ required: true })
  private pagination!: PaginatorMeta

  @Prop({ required: true })
  private filter!: ITableOptions

  @Prop({ default: false })
  private roundedBottomOff!: boolean

  private get pageInfo () {
    const { pagination } = this
    return (pagination.from && pagination.to) ? `${pagination.from} - ${pagination.to} из ${pagination.total}` : ''
  }

  private handleChangePage () {
    this.$emit('update:filter', this.filter)
  }
}
