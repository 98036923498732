



















import { Component, Prop, Vue } from 'vue-property-decorator'
import Swiper from 'swiper'
import { Autoplay, Navigation } from 'swiper/modules'

// components
import MasterCoursesBannerCard from '@/components/cards/MasterCoursesBannerCard.vue'
// store
import { SaleBannerResource } from '@/store/types'
// utils
import { randomColorBanner } from '@/utils/functions'

@Component({
  components: {
    MasterCoursesBannerCard,
  },
})
export default class StoreBannerSlider extends Vue {
  @Prop({ required: true })
  private discounts!: SaleBannerResource[]

  private swiper: any = null
  private sliderOptions = {
    slidesPerView: 1,
    spaceBetween: 24,
  }

  private color = ''

  private mounted() {
    this.color = randomColorBanner()
    this.swiper = new Swiper('.swiper', {
      ...this.sliderOptions,
      autoplay: {
        delay: 5000,
        pauseOnMouseEnter: true,
      },
      loop: true,
      navigation: {
        disabledClass: 'v-btn--disabled',
        nextEl: '.banner-slider__nav_next',
        prevEl: '.banner-slider__nav_prev',
      },
      speed: 500,
      modules: [Autoplay, Navigation],
    })
  }

  private destroyed() {
    this.swiper.destroy()
  }
}
